import { Button, Card, DatePicker, Select, Switch, Table } from "antd";
import moment from 'moment';
import React, { useState } from "react";
import CsvDownload from 'react-json-to-csv';
import { connect } from 'react-redux';
import ReportService from '../../redux/services/reports';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ItemActivity = (props) => {
    const [tableData, setTableData] = useState([]);
    const [boardId, setBoardId] = useState(null);
    const [timeoutVar, setTimeoutVar] = useState(null);
    const [showCompleted, setShowCompleted] = useState(false);
    const [columns, setColumns] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [dataTobeExport, setDataTobeExport] = useState([]);


    const getReport = () => {
        if (boardId == null || boardId < 0)
            return;
        ReportService.itemActivities(boardId, showCompleted, startDate, endDate).then(res => {
            processData(res);
            if (timeoutVar != null) {
                clearTimeout(timeoutVar);
            }
            const myTimeout = setTimeout(() => {
                getReport();
            }, 120000);
            setTimeoutVar(myTimeout);
        })
    }

    const exportSummaryReport = async () => {
        if (boardId == null || boardId < 0)
            return;
        await ReportService.itemActivitiesExport(boardId);
    }

    const onChange = (isChecked) => {
        setShowCompleted(isChecked);
        // if (boardId == null || boardId < 0)
        //     return;
        // ReportService.itemActivities(boardId, isChecked).then(res => {
        //     processData(res);
        //     if (timeoutVar != null) {
        //         clearTimeout(timeoutVar);
        //     }
        //     const myTimeout = setTimeout(() => {
        //         getReport();
        //     }, 120000);
        //     setTimeoutVar(myTimeout);
        // })
    }

    const processData = (dataset) => {
        let columns = [];
        let header = [];
        let rows = [];
        let row = ['Ref No.', 'Board', 'Item', 'Priority', 'Start Date'];


        dataset?.lists?.map(list => row.push(list + " (in minutes)"));

        row.push('Total Time Spent')
        row.push('% complete (average of all the percentage)')
        row.push('Current List')

        row.map((item, index) => {
            header.push(item);
            columns.push({
                title: item,
                dataIndex: index,
                width: 120,
            })
        });
        // debugger;
        setColumns(columns);
        dataset?.items?.map(item => {
            let total_time_spent = 0;
            let dt = moment(item.created_at).format('MMM D, YYYY');

            row = [item.item__id, dataset.board, item.item__title, item.item__priority__title, dt];
            dataset?.lists?.map(list => {
                const time_spent = timeSpent(dataset, list, item.item__title);
                total_time_spent += parseInt(time_spent)
                row.push(time_spent)
            });
            row.push(total_time_spent)
            row.push(item.percentage)
            row.push(item.item__list__title)
            rows.push(row);
        });

        setTableData(rows);
        var _rows = [];
        // debugger;
        rows.forEach(element => {
            let _row = {}
            header.forEach((head, indx) => {
                _row['"' + head + '"'] = element[indx].toString().replaceAll('"', "'")
            });
            _rows.push(_row)
        });

        // rows.splice(0, 0, header);

        setDataTobeExport(_rows);
    }

    const timeSpent = (dataset, list, item) => {
        const data = dataset?.data?.filter((x) => x.list == list && x.item == item);
        if (data != null && data.length > 0) {
            return `${data[0]?.time_spent}`;
        }
        return "0";
    }

    const onStartDateChange = (date, dateString) => {
        setStartDate(dateString);
    }

    const onEndDateChange = (date, dateString) => {
        setEndDate(dateString);
    }

    const disabledStartDate = (current, x, y) => {
        if (endDate != null && endDate != "") {
            return current > moment(endDate) || current < moment(endDate).subtract(7, 'day');
        }
        return current > moment();
    }

    const disabledEndDate = (current, x, y) => {
        return current < moment(startDate) || current > moment(startDate).add(7, 'day');
    }

    return (
        <Card style={{ maxWidth: 'calc(100% - 1px)' }} title={
            <div>
                Cadence Report &nbsp;&nbsp;&nbsp;
                <Select defaultValue={-1} style={{ width: 180 }} onChange={(id) => { setBoardId(id) }}>
                    <Option value={-1}>--Select Board--</Option>
                    {
                        props?.boards.map(board => {
                            return <Option key={`board_${board.id}`} value={board.id}>{board.title}</Option>
                        })
                    }
                </Select>
                &nbsp;&nbsp;&nbsp;

                Date From: <DatePicker onChange={onStartDateChange} disabledDate={disabledStartDate} />
                &nbsp;&nbsp;&nbsp;
                Date To: <DatePicker onChange={onEndDateChange} disabledDate={disabledEndDate} />


                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Switch unCheckedChildren="Show Completed" checkedChildren="Hide Completed" onChange={(v) => onChange(v)} />
                &nbsp;&nbsp;&nbsp;
                <Button type="primary" disabled={boardId == null} onClick={getReport}>Submit</Button>
                &nbsp;&nbsp;&nbsp;
                <Button type="primary" disabled={boardId == null} onClick={exportSummaryReport}>Export Task Status</Button>
            </div>
        }>
            <div style={{ textAlign: 'right' }}>
                <CsvDownload data={dataTobeExport} enclosingCharacter={`'`} filename="item_activity_report.csv">Export</CsvDownload>
            </div>
            <div style={{ maxWidth: 'calc(100% - 1px)', overflow: 'auto' }}>
                <Table pagination={false} columns={columns} dataSource={tableData} scroll={{ y: window.innerHeight - 280 }} />
            </div>
        </Card>
    );
};

export default connect(null)(ItemActivity);
