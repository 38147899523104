import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import { GetUserDetail, login } from '../../redux/actions/auth';



export class LoginTabset extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user_email: "",
            login_password: "",
            username: "",
            password: "",
            email: "",
        }
        // setTimeout(() => {
        //     this.generateQRCode();
        // }, 2000);
        // QRInterval = setInterval(() => {
        //     this.generateQRCode();
        // }, 30000);
    }


    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove("show");
        event.target.classList.add("show");
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    getUserDetails = () => {
        this.props.dispatch(GetUserDetail()).then(resp => {

            if (resp?.permissions?.can_access_spc) {
                this.props.history.push('/spc');
            }
            else if (resp.permissions?.can_access_jobs) {
                this.props.history.push('/jobs');
            }
            else if (resp.permissions?.can_access_boards) {
                this.props.history.push('/boards');
            }
            else if (resp.permissions?.can_access_forms) {
                this.props.history.push('/forms');
            }
            else {
                this.props.history.push('/permission-denied');
            }

        })
    }

    loginF = (event, form) => {
        event.preventDefault();
        this.props
            .dispatch(
                login(this.state.user_email, this.state.login_password)
            )
            .then((res) => {
                this.getUserDetails();
            })
            .catch(() => {
                toast.error("credential is not valid")
                if (this.props.message === "Account Not Verified") {
                    this.props.history.push("/verify/account/")
                }
            })
    }

    resetField = () => {
        this.setState({
            username: "",
            email: "",
            password: ""
        })
    }

    // generateQRCode = () => {

    //     this.setState({ qrCodeLoading: true });
    //     axios.get(`${appUrl}/qr-session/?session_id=${this.state.sessionId}`) // API to generate a unique QR token
    //         .then(response => {
    //             this.setState({
    //                 qrCodeToken: response.data.data,
    //                 qrCodeLoading: false,
    //             });
    //         })
    //         .catch(() => {
    //             toast.error("Failed to generate QR code");
    //             this.setState({ qrCodeLoading: false });
    //         });
    // };

    // pollQRCodeLogin = (token) => {
    //     pollInterval = setInterval(() => {

    //         axios.get(`${appUrl}/qr-status/${token}/`) // Poll for login status
    //             .then(response => {
    //                 if (response.data.status === "COMPLETED") {
    //                     clearInterval(pollInterval);
    //                     clearInterval(QRInterval);
    //                     this.getUserDetails();
    //                     // this.props.dispatch(GetUserDetail()).then(() => {
    //                     //     this.props.history.push('/'); // Redirect after successful login
    //                     // });
    //                 }
    //             })
    //             .catch(() => {
    //                 clearInterval(pollInterval);
    //                 toast.error("QR code login failed");
    //             });
    //     }, 5000); // Poll every 30 seconds

    //     // setTimeout(() => {
    //     //     clearInterval(interval);
    //     //     this.setState({ qrCodeToken: null });
    //     // }, 60000); // Expire after 60 seconds
    // };

    render() {

        return (
            <div>
                <Form className="form-horizontal auth-form" onSubmit={this.loginF}>
                    <h4 className="mb-4 sign-in-class">Sign In</h4>
                    <FormGroup>
                        <Label>Email Address</Label>
                        <Input
                            name="user_email"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Enter Username"
                            id="exampleInputEmail1"
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Password</Label>
                        <Input
                            name="login_password"
                            type="password"
                            required
                            className="form-control"
                            placeholder="Password"
                            onChange={this.handleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label check>
                            <Input type="checkbox" />{' '}
                            Remember Me
                        </Label>
                    </FormGroup>
                    <div className="form-button">
                        <div className="text-center">
                            <Button
                                color="primary"
                                type="submit"
                                className="text-align-center"
                            >
                                Login
                            </Button>
                        </div>
                    </div>
                </Form>
                <div>
                    <ToastContainer />
                </div>



            </div>
        );
    }
};

function mapStateToProps(state) {
    const { message } = state.message;
    return {
        message
    };
}


export default connect(mapStateToProps)(withRouter(LoginTabset));
