import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { GetUserDetail, QRLogin } from '../../redux/actions/auth';
import { appUrl } from "../../redux/services/base";

const QRCodeComp = (props) => {
    const history = useHistory()
    const [qrCodeToken, setQRCodeToken] = useState(null);
    const [sessionId, setSessionId] = useState(null);

    const qrCodeTokenRef = useRef(qrCodeToken);
    const verificationTimerRef = useRef(null);
    const qrTimerRef = useRef(null);

    useEffect(() => {
        let id = uuid();
        setSessionId(id);
        generateQRCode(id); // Initial QR code generation
        startTimers(); // Start the timers to regenerate QR code and poll for status
        return () => {
            clearTimers(); // Cleanup on component unmount
        };
    }, []);

    useEffect(() => {
        qrCodeTokenRef.current = qrCodeToken; // Update the ref whenever qrCodeToken changes
    }, [qrCodeToken]);


    const clearTimers = () => {
        if (qrTimerRef.current) clearInterval(qrTimerRef.current);
        if (verificationTimerRef.current) clearInterval(verificationTimerRef.current);
    };

    const startTimers = () => {
        // Start the QR code generation timer (every 30 seconds)
        qrTimerRef.current = setInterval(() => {
            generateQRCode();
        }, 30000);

        // Start the verification check timer (every 5 seconds)
        verificationTimerRef.current = setInterval(() => {
            pollQRCodeLogin();
        }, 5000);
    };



    const getUserDetails = () => {
        props.dispatch(GetUserDetail()).then(resp => {
            if (resp?.permissions?.can_access_spc) {
                history.push('/spc');
            }
            else if (resp.permissions?.can_access_jobs) {
                history.push('/jobs');
            }
            else if (resp.permissions?.can_access_boards) {
                history.push('/boards');
            }
            else if (resp.permissions?.can_access_forms) {
                history.push('/forms');
            }
            else {
                history.push('/permission-denied');
            }

        })
    }


    const pollQRCodeLogin = () => {
        const currentToken = qrCodeTokenRef.current;

        if (!currentToken) {
            console.log('qrCodeToken is null or blank');
            return;
        }

        axios.get(`${appUrl}/qr-status/${currentToken}/`) // Poll for login status
            .then(response => {
                if (response.data.access != null) {
                    clearTimers();
                    props.dispatch(
                        QRLogin(response.data.access, response.data.refresh)
                    ).then(() => {
                        getUserDetails();
                    });
                }
            })
            .catch((err) => {
                console.error('Poll Err > ', err);
            });
    };

    const generateQRCode = (id) => {
        let sid = sessionId || id;

        axios.get(`${appUrl}/qr-session/?session_id=${sid}`) // API to generate a unique QR token
            .then(response => {
                setQRCodeToken(response.data.data); // Update state with new QR code token
            })
            .catch((err) => {
                console.error('Error generating QR code: ', err);
            });
    };

    return (
        <div>
            {
                qrCodeToken == null ? (<span>Loading...</span>) : (
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={`@@${qrCodeToken}@@`}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                )
            }
        </div>

    );
};



function mapStateToProps(state) {
    const { token } = state.auth;
    return {
        token
    }
}

export default connect(mapStateToProps)(QRCodeComp);
