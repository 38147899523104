import { Button, Card, DatePicker, Select, Table } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import React, { useState } from "react";
import CsvDownload from 'react-json-to-csv';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import ReportService from '../../redux/services/reports';

dayjs.extend(customParseFormat);

const { Option } = Select;

const ArchivedList = (props) => {
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [boardId, setBoardId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [columns, setColumns] = useState([]);
    const [boardTitle, setBoardTitle] = useState('');

    const [dataTobeExport, setDataTobeExport] = useState([]);

    const onBoardChange = (value, data) => {
        if (value == "" || value < 0)
            return;
        setBoardTitle(data.title);
        setBoardId(value);
    }

    const getReport = () => {
        if (boardId != null && boardTitle != '') {
            ReportService.getArchivedItems(boardId, startDate, endDate).then(res => {
                processData(res.results);
            });
        }
    }

    const processData = (dataset) => {
        let rows = [];
        let columns = [
            {
                title: 'Ref No.',
                dataIndex: 'id',
                width: 30,
                key: 'id',
                render: (_, record) => (
                    <Button type="link" target="_blank" onClick={() => { handleClick(record) }}>{record.id}</Button>
                )
            },
            {
                title: 'Board',
                render: (_, record) => (
                    <span>{boardTitle}</span>
                ),
                width: 100,
                key: 'id'
            },
            {
                title: 'List',
                dataIndex: 'list_title',
                width: 100,
                key: 'id'
            },
            {
                title: 'Priority',
                render: (_, record) => (
                    <span>{record.priority.title}</span>
                ),
                width: 30,
                key: 'id'
            },
            {
                title: 'Item',
                dataIndex: 'title',
                width: 150,
                key: 'id'
            },
            // {
            //     title: 'Action',
            //     key: 'action',
            //     width: 50,
            //     key: 'id',
            //     render: (_, record) => (
            //         <Button onClick={() => { Unarchive(record) }} type="primary">Unarchive</Button>
            //     )
            // }
        ];

        let header = ['Ref No.', 'Board', 'List', 'Item', 'Priority'];
        setColumns(columns);

        dataset?.map(item => {
            rows.push([item.id.toString(), boardTitle, item.list_title, item.title, item?.priority?.title]);
        });

        setTableData(dataset);
        rows.splice(0, 0, header);
        setDataTobeExport(rows);
    }

    const handleClick = (data) => {
        history.push({ pathname: `/task-detail/${boardId}/${data.list_id}/${data.id}`, search: "?next=/archived/list/report" });
    }

    const disabledDate = (current, x, y) => {
        if (endDate != null && endDate != "") {
            return current > moment(endDate) || current < moment(endDate).subtract(7, 'day');
        }
        return current > moment();
    }

    const disabledEndDate = (current, x, y) => {
        return current < moment(startDate) || current > moment(startDate).add(7, 'day');
    }

    const onStartDateChange = (date, dateString) => {
        setStartDate(dateString);
    }

    const onEndDateChange = (date, dateString) => {
        setEndDate(dateString);
    }



    return (
        <Card style={{ maxWidth: 'calc(100% - 1px)' }} title={
            <div>
                Select Board &nbsp;&nbsp;&nbsp;
                <Select defaultValue={-1} style={{ width: 180 }} onChange={onBoardChange}>
                    <Option value={-1}>--Select Board--</Option>
                    {
                        props?.boards.map((board, index) => {
                            return <Option key={`board_${index}`} title={board.title} value={board.id}>{board.title}</Option>
                        })
                    }
                </Select>

                &nbsp;&nbsp;&nbsp;
                Date From: <DatePicker onChange={onStartDateChange} disabledDate={disabledDate} />
                &nbsp;&nbsp;&nbsp;
                Date To: <DatePicker onChange={onEndDateChange} disabledDate={disabledEndDate} />
                &nbsp;&nbsp;&nbsp;
                <Button type="primary" onClick={getReport}>Submit</Button>
            </div>
        }>
            <div style={{ textAlign: 'right' }}>
                {
                    dataTobeExport.length > 1 ? <CsvDownload data={dataTobeExport} filename="archived_item_report.csv">Export</CsvDownload> : null
                }

            </div>
            <div style={{ maxWidth: 'calc(100% - 1px)', overflow: 'auto' }}>
                <Table pagination={false} columns={columns} dataSource={tableData} scroll={{ y: window.innerHeight - 280 }} />
            </div>
        </Card>
    )
};

export default connect(null)(ArchivedList);