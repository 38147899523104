
import { MoreOutlined } from '@ant-design/icons';
import { Button as AntdButton, Dropdown, Menu, Modal } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    Card, CardBody,
    CardHeader,
    CardTitle, Table
} from 'reactstrap';
import { GetRecords } from "../../../redux/actions/forms";
import FormService from "../../../redux/services/forms";

import { DeleteOutlined, LoadingOutlined, UndoOutlined } from '@ant-design/icons';

import Box from '../primitive/Box';
import Button from '../primitive/Button';
import Check from '../primitive/Check';
import Drop from '../primitive/Drop';
import Image from '../primitive/Image';
import Input from '../primitive/Input';
import Label from '../primitive/Label';
import List from '../primitive/List';

import Radio from '../primitive/Radio';
import Signature from './components/Signature';
import Snapshot from './components/Snapshot';



import axios from "axios";
import authHeader from "../../../redux/services/auth-header";
import { formsUrl } from "../../../redux/services/base";
import database from '../../firebasedb';
import process_vars from '../../forms/intermediate/process_vars';
import InputTable, { process_table_vars, renderTableLabelText } from '../records/components/InputTable';

const math = require('mathjs');


const contentFor = async (record, page) => {
    let components_render = [];
    let components = record.content;
    let vars = record.vars;
    for (let k = 0; k < components.length; ++k) {
        if (components[k].page === page) {
            if (components[k].type === 'primitive/Box') {
                components_render.push(
                    <Box id={k} key={k} comps={components} vw={window.innerWidth} vars={vars} />
                );
            } else if (components[k].type === 'primitive/Label') {
                components_render.push(
                    <Label id={k} key={k} comps={components} vw={window.innerWidth} vars={vars} pdf={true} />
                );
            } else if (components[k].type === 'primitive/Button') {
                components_render.push(<Button key={k} id={k} comps={components} vw={window.innerWidth} vars={vars} />);
            } else if (components[k].type === 'primitive/Input') {
                components_render.push(<Input id={k} key={k} comps={components} vw={window.innerWidth} vars={vars} pdf={true} />);
            } else if (components[k].type === 'primitive/List') {
                components_render.push(
                    <List id={k} key={k} comps={components} vw={window.innerWidth} vars={vars} pdf={true} />
                );
            } else if (components[k].type === 'primitive/Check') {
                components_render.push(
                    <Check id={k} key={k} comps={components} vw={window.innerWidth} vars={vars} pdf={true} />
                );
            } else if (components[k].type === 'primitive/Radio') {
                let rad = <Radio id={k} key={k} comps={components} vw={window.innerWidth} vars={vars} pdf={true} />;

                components_render.push(rad);
            } else if (components[k].type === 'primitive/Drop') {
                components_render.push(
                    <Drop id={k} key={k} comps={components} vw={window.innerWidth} vars={vars} pdf={true} />
                );
            } else if (components[k].type === 'primitive/Image') {
                components_render.push(
                    <Image id={k} key={k} comps={components} vw={window.innerWidth} vars={vars} pdf={true} />
                );
            } else if (components[k].type === 'intermediate/Signature') {
                if (components[k].props.image_id != null && components[k].props.image_id != undefined) {
                    const resp = await axios.get(`${formsUrl}/records/image/${components[k].props.image_id}/get/`, { headers: authHeader() });
                    if (resp.status == 200) {
                        var s3ImageUrl = resp.data.image;
                        var sigData = await fetch(s3ImageUrl)
                            .then(response => response.blob())
                            .then(blob => {
                                return new Promise((resolve, reject) => {
                                    const reader = new FileReader();
                                    reader.onloadend = () => resolve(reader.result.split(',')[1]);
                                    reader.onerror = reject;
                                    reader.readAsDataURL(blob);
                                });
                            })
                            .then(base64Data => {
                                return `data:image/png;base64,${base64Data}`;
                            })
                            .catch(error => {
                                console.error('Error fetching or converting image:', error);
                                return null;
                            });

                        if (sigData != null) {
                            components[k].props.data = sigData;
                        }
                    }
                }
                components_render.push(
                    <Signature key={k} id={k} comps={components} vw={0.9 * window.innerWidth} vars={vars} pdf={true} />
                );
            } else if (components[k].type === 'intermediate/InputTable') {
                let tab = <InputTable key={k} id={k} comps={components} vw={0.9 * window.innerWidth} vars={vars} pdf={true} />;
                components_render.push(tab);
            }
            else if (components[k].type === 'intermediate/Snapshot') {
                if (components[k].image_id != null && components[k].image_id != undefined) {
                    const resp = await axios.get(`${formsUrl}/records/image/${components[k].image_id}/get/`, { headers: authHeader() });
                    if (resp.status == 200) {
                        var s3ImageUrl = resp.data.image;

                        var sigData = await fetch(s3ImageUrl)
                            .then(response => response.blob())
                            .then(blob => {
                                return new Promise((resolve, reject) => {
                                    const reader = new FileReader();
                                    reader.onloadend = () => resolve(reader.result.split(',')[1]);
                                    reader.onerror = reject;
                                    reader.readAsDataURL(blob);
                                });
                            })
                            .then(base64Data => {
                                return `data:image/png;base64,${base64Data}`;
                            })
                            .catch(error => {
                                console.error('Error fetching or converting image:', error);
                                return null;
                            });
                        if (sigData != null) {
                            components[k].data = sigData;
                        }
                    }
                }
                components_render.push(
                    <Snapshot id={k} comps={components}
                        vw={0.9 * window.innerWidth} vars={vars} pdf={true} />
                );
            }
        }
    }

    // console.log('Page > height  > ', (record.pages[page].height));
    // console.log('Page height > ', (record.pages[page].height * 90));
    return <div style={{
        position: 'relative',
        background: '#ffffff',
        width: '100vw',
        height: (record.pages[page].height * 100).toString() + 'vw',
        // margin: '2.5vmax',
        // marginTop: '20px'
    }}>{components_render}</div>;
}



const Records = (props) => {
    const { formId, formName } = useParams();
    const history = useHistory();
    const location = useLocation();
    const downloadRef = useRef();
    const downloadRef1 = useRef();

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        // Reference to the desired location in your Realtime Database
        const databaseRef = database.ref('/records');

        // Event listener for 'value' changes in the database
        const onDataChange = (snapshot) => {
            const newData = snapshot.val();
            console.log('newData > ', newData);
            getRecords("", false);
            //   setData(newData);
        };

        // Set up the listener
        databaseRef.on('value', onDataChange);

        // Clean up the listener when the component is unmounted
        return () => {
            databaseRef.off('value', onDataChange);
        };
    }, []);

    const getRecords = (url, showLoader) => {
        let _formId = formId;
        if (props?.formId != null) {
            _formId = props?.formId;
        }
        if (showLoader)
            setLoading(true);
        let payload = {
            "formId": formId,
            "formType": props?.formType
        };
        if (props?.filters != null && props?.filters != undefined) {
            payload = props?.filters;
            payload["formId"] = formId
            payload["flag"] = payload?.flag?.join(",");
            payload["formType"] = props?.formType;
        }
        props.dispatch(GetRecords(payload, url)).then(res => {
            if (showLoader)
                setLoading(false);
        });
    }

    useEffect(() => {
        getRecords("", true);

    }, [props?.filters, props?.formType, props?.refresh])

    const [showPDFModal, setShowPDFModal] = useState(false);

    const [pdfPages, setPdfPages] = useState([]);

    const exportAsPDF = async (e, record) => {
        // setShowPDFModal(true);
        setLoading(true);
        let pages = [];
        let downloadDiv = document.getElementById("downloadDiv");




        var doc = null;

        for (let j = 0; j < record.pages.length; j++) {
            var contentData1 = await contentFor(record, j);
            // downloadDiv.innerHTML = renderToStaticMarkup(contentData1);
            // debugger;
            pages.push(contentData1);
        }

        for (let j = 0; j < record.pages.length; j++) {
            // var contentData1 = await contentFor(record, j);
            // pages.push(contentData1);

            var contentData1 = pages[j];

            downloadDiv.innerHTML = renderToStaticMarkup(contentData1);
            // let height1 = (downloadDiv.scrollHeight - 950) * 0.2645833333;
            // var height2 = document.getElementById("downloadDiv").clientHeight;
            // console.log('height1 > ', downloadDiv.scrollHeight);
            // console.log('height2 > ', height2);
            // let pageHeight = 297;
            // if (downloadDiv.scrollHeight > 1500 && downloadDiv.scrollHeight < 2300) {
            //     pageHeight = pageHeight * 2;
            // }
            // if (downloadDiv.scrollHeight > 2300 && downloadDiv.scrollHeight < 3500) {
            //     pageHeight = pageHeight * 3;
            // }
            // console.log('pageHeight > ', pageHeight);
            // pageHeight = height2 * 0.26458333333719;
            // console.log('pageHeight > ', pageHeight);
            if (j == 0 && doc == null) {
                doc = new jsPDF('p', 'mm', [210, 297]);
            }
            await downloadRecord(doc, j, record.pages.length - 1);
        }
        setPdfPages(pages);
        setLoading(false);
    }

    const deleteRecord = (record, d_type) => {
        if (d_type == 1 || d_type == 2) {
            if (window.confirm('Are you sure want to delete this record?')) {
                setLoading(true);
                FormService.deleteRecord({ "id": record.record_id, "type": d_type }).then(res => {
                    setLoading(false);
                    setTimeout(() => {
                        getRecords("", true);
                    }, 500);
                }).catch(res => {
                    if (res.response.status == 400) {
                        alert(res.response.data.message);
                    }
                    setLoading(false);
                });
            }
        }
        else {
            setLoading(true);
            FormService.deleteRecord({ "id": record.record_id, "type": 0 }).then(res => {
                setLoading(false);
                setTimeout(() => {
                    getRecords("", true);
                }, 500);
            }).catch(res => {
                setLoading(false);
            });
        }
    }

    function downloadRecord(doc, page_num, num_of_pages) {
        return new Promise((res) => {
            let downloadDiv = document.getElementById("downloadDiv");


            html2canvas(downloadDiv).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const imgWidth = 210;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;


                if (page_num !== 0) {
                    doc.addPage();
                }
                doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                if (page_num === num_of_pages) {
                    doc.save("record.pdf");
                }
                res(true);
            }).catch(function (error) {
                debugger;
                console.error("domToImage error", error);
                res(true);
            });
        });
    };

    function renderLabelText(text, vars) {
        try {
            let parsedText = text;

            let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
            let matchResults = [...parsedText.matchAll(mathRegex)];
            matchResults.forEach(result => {
                let mathExp = process_vars(result[1], vars);
                let evaluatedExp = 0;
                try {
                    evaluatedExp = math.evaluate(mathExp).toString();
                    if (result[3] != null) {
                        evaluatedExp = parseFloat(evaluatedExp).toFixed(parseInt(result[3]));
                    }
                } catch (e) {
                    // do nothing
                }
                parsedText = parsedText.replace(result[0], evaluatedExp);
            });

            parsedText = process_vars(parsedText, vars);
            return parsedText;
        } catch (e) {
            return text;
        }
    }

    const exportCSV = () => {
        if (props?.records?.results.length > 0) {
            let rowsForEachRecord = 1;
            let csvFile = ["Record Name", "Created On", "Last Modified On", "Time Lapsed"];

            props?.records?.results[0].externals.flags.forEach(flag => {
                let flagName = flag.name.replaceAll(',', '');
                csvFile.push(`Flag: ${flagName}`);
            });

            props?.records?.results[0].content.forEach(element => {
                if (element.type === "intermediate/InputTable") {
                    if (element.data.length > rowsForEachRecord) {
                        rowsForEachRecord = element.data.length;
                    }

                    csvFile.push(element.name.replaceAll(',', ''));

                    for (let k = 0; k < element.data[0].length; ++k) {
                        csvFile.push(`${element.colData[k].name.replaceAll(',', '')}`);
                    }
                }
            });

            props?.records?.results[0].content.forEach(element => {
                if (element.type === 'primitive/Input' || element.type === 'primitive/Radio' || element.type === 'primitive/Check' || element.type === 'primitive/Drop') {
                    csvFile.push(element.name.replaceAll(',', ''));
                }
                if (element.type === 'primitive/Label' && element?.props?.appearsInCSV) {
                    csvFile.push(element.name.replaceAll(',', ''));
                }
            });

            csvFile.push('Record URI');
            csvFile = [csvFile];
            props?.records?.results.forEach(inputRecord => {
                let reorderedRecordContent = [[], []]; // first index contains tables, next index contains all else
                inputRecord.content.forEach(element => {
                    if (element.type === 'intermediate/InputTable') {
                        reorderedRecordContent[0].push({ ...element });
                    } else {
                        reorderedRecordContent[1].push({ ...element });
                    }
                });

                let record = {
                    ...inputRecord,
                };

                record.content = reorderedRecordContent[0].concat(reorderedRecordContent[1]);
                let created_at_date = new Date(inputRecord.created_at);
                let updated_at_date = new Date(inputRecord.updated_at);

                const difference_between = (date1, date2) => {
                    let diff = date2 - date1; // in milliseconds
                    let hourDiff = Math.floor(diff / (1000 * 60 * 60));
                    let minuteDiff = Math.floor(diff / (1000 * 60)) - (60 * hourDiff);
                    let secondDiff = Math.floor(diff / (1000)) - (60 * 60 * hourDiff) - (60 * minuteDiff);

                    let hrStr = `${hourDiff}`;
                    let mnStr = `${minuteDiff}`;
                    let scStr = `${secondDiff}`;

                    if (hrStr.length === 1) {
                        hrStr = '0' + hrStr;
                    } if (mnStr.length === 1) {
                        mnStr = '0' + mnStr;
                    } if (scStr.length === 1) {
                        scStr = '0' + scStr;
                    }

                    return `${hrStr}:${mnStr}:${scStr}`;
                };

                // const c_dt = created_at_date.toLocaleDateString();
                // const c_tm = created_at_date.toLocaleTimeString();

                // const u_dt = updated_at_date.toLocaleDateString();
                // const u_tm = updated_at_date.toLocaleTimeString();
                // debugger;
                let csvRow = [
                    [
                        '"' + record.name.toString().replaceAll(',', '') + '"',
                        '"' + moment(created_at_date).format('lll') + '"',
                        '"' + moment(updated_at_date).format('lll') + '"',
                        // c_dt
                        // c_tm,
                        // u_dt,
                        // u_tm,
                        '"' + difference_between(created_at_date, updated_at_date) + '"'
                    ]
                ];

                for (let k = 0; k < rowsForEachRecord - 1; ++k) {
                    let rowToPush = [];
                    for (let j = 0; j < csvFile.length; ++j) {
                        rowToPush.push('""');
                    }
                    csvRow.push('"' + rowToPush + '"');
                }

                record.externals.flags.forEach(flag => {
                    csvRow[0].push(`"${flag.values[flag.value]}"`.replaceAll(',', ''));
                });

                let pushForward = 0;
                for (let k = 0; k < record.content.length; k++) {
                    let k_ = k + pushForward;
                    let element = record.content[k];
                    if (element.type === 'primitive/Input') {
                        csvRow[0].push('"' + element.props.value + '"');
                    }
                    else if (element.type === 'primitive/Label' && element?.props?.appearsInCSV) {
                        csvRow[0].push('"' + renderLabelText(element.props.text, inputRecord.vars) + '"');
                    }
                    else if (element.type === 'primitive/Radio') {
                        try {
                            csvRow[0].push('"' + element.props.content[parseInt(element.props.selected)] + '"');
                        } catch (e) {
                            csvRow[0].push('""');
                        }
                    } else if (element.type === 'primitive/Check') {
                        let checkContent = [];
                        element.props.selected.forEach(id => {
                            checkContent.push(element.props.content[parseInt(id)].replaceAll(',', ''));
                        }); csvRow[0].push('"' + checkContent.join('/') + '"');
                    } else if (element.type === 'primitive/Drop') {
                        try {
                            csvRow[0].push('"' + element.props.content[parseInt(element.props.selected)] + '"');
                        } catch (e) {
                            csvRow[0].push('""');
                        }
                    } else if (element.type === 'intermediate/InputTable') {
                        let currentCol = 6 + props?.records?.results[0].externals.flags.length + k_;
                        for (let j = 0; j < element.data.length; ++j) {
                            try {
                                csvRow[j][currentCol] = `Row ${j + 1}`;
                            }
                            catch (e) {
                                // csvRow[j][currentCol] = `Row ${j + 1}`;
                            }
                        }
                        for (let i = 0; i < element.data.length; ++i) {
                            for (let j = 1; j < element.data[0].length + 1; ++j) {
                                if (element.colData[j - 1].type === "Drop") {
                                    try {
                                        csvRow[i][currentCol + j] = element.colData[j - 1].values[element.data[i][j - 1]].replaceAll(',', '');
                                    } catch (e) {
                                        //csvRow[i][currentCol + j] = '';
                                    }
                                } else {
                                    if (element.colData[j - 1].type === "Label") {
                                        try {
                                            csvRow[i][currentCol + j] = renderTableLabelText(record.content, k, element.data[i][j - 1], i, process_table_vars).replaceAll(',', '');
                                        }
                                        catch (e) {

                                        }
                                    } else {
                                        try {
                                            csvRow[i][currentCol + j] = element.data[i][j - 1].toString().replaceAll(',', '');
                                        } catch (e) {
                                            try {
                                                csvRow[i][currentCol + j] = element.data[i][j - 1].toString();
                                            } catch (e) {
                                                //csvRow[i][currentCol + j] = '';
                                            }
                                        }
                                    }

                                }
                            }
                        } pushForward = pushForward + element.data[0].length;
                    }
                }

                csvRow[0].push(
                    `"${window.location.href.split('/')[2]}/forms/${formId}/records/${record.record_id}/change"`
                );
                // /forms/46/records/118/change

                for (let k = 0; k < csvRow.length; ++k) {
                    csvFile.push(csvRow[k]);
                }
            });

            var csv = csvFile.map(function (d) {
                return d.join();
            }).join('\n');
            const blob = new Blob([csv], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = 'Data for ' + formName;
            link.href = url;
            link.click();
        }
    }



    const generateCard = (table) => {
        return <Card>
            <CardHeader>
                <CardTitle>
                    Records of <strong>{formName}</strong>
                    <AntdButton style={{ float: 'right' }} type="primary" onClick={(e) => {
                        history.push({
                            pathname: `/forms/${formId}/records/create`
                            // state: location.state
                        });
                    }}>Add New Record</AntdButton>

                    <AntdButton style={{ float: 'right', marginRight: 10 }} onClick={(e) => {
                        history.push('/forms')
                    }} type="primary">Go Back</AntdButton>

                    <AntdButton type="primary" style={{ float: 'right', marginRight: 10 }} onClick={(e) => {
                        e.preventDefault();
                        exportCSV();
                    }}>Export Data to CSV</AntdButton>



                </CardTitle>
            </CardHeader>
            <CardBody>
                {table}
            </CardBody>
        </Card >
    }

    const generateTable = () => {


        return <div id="wrapper">
            <div id="downloadDiv1" ref={downloadRef1}></div>
            <div style={{ background: '#fff', position: 'absolute', left: -4000 }}>
                <div id="downloadDiv" ref={downloadRef}></div>
            </div>
            <Table responsive striped hover className="tbl-small">
                <thead>
                    <tr>
                        <th>Record Name</th>
                        <th>Created By</th>
                        <th>Create On</th>

                        <th>Last Modified By</th>
                        <th>Last Modified On</th>

                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props?.records?.results?.map((record, index) => {
                            return (
                                <tr key={`record_+${index}`}>
                                    <td>
                                        <span>
                                            {
                                                record?.externals?.flags?.map((flag, f_indx) => {
                                                    return <i className='fa-solid fa-flag' key={`flag_${f_indx}`} title={`${flag.name}: ${flag.value_text}`} style={{
                                                        fontSize: '1.15vmax',
                                                        color: flag.colors[flag.value],
                                                        border: '0pt',
                                                        marginRight: '0.5vmax'
                                                    }}></i>
                                                })
                                            }
                                        </span>
                                        {record.name}
                                    </td>

                                    <td>{record.created_by.first_name} {record.created_by.last_name}</td>
                                    <td>{moment(record.created_at).format('lll')}</td>


                                    <td>{record.updated_by?.first_name} {record.updated_by?.last_name}</td>

                                    <td>{moment(record.updated_at).format('lll')}</td>

                                    {
                                        record.is_deleted ? (
                                            <td>

                                                <Dropdown style={{ position: 'absolute', top: 5, right: 5 }}
                                                    trigger={['click']}
                                                    overlay={
                                                        <Menu className="item_menu">
                                                            <Menu.Item key={`1`} onClick={(e) => {
                                                                deleteRecord(record, 0);
                                                            }} > <UndoOutlined /> Restore</Menu.Item>

                                                            <Menu.Item key={`2`} onClick={(e) => {
                                                                deleteRecord(record, 2);
                                                            }}><DeleteOutlined /> Delete</Menu.Item>
                                                        </Menu>
                                                    }>
                                                    <MoreOutlined style={{ fontSize: 20, fontWeight: 'bold' }} />
                                                </Dropdown>
                                            </td>

                                        ) : (
                                            <td>

                                                <Dropdown style={{ position: 'absolute', top: 5, right: 5 }}
                                                    trigger={['click']}
                                                    overlay={

                                                        <Menu className="item_menu">
                                                            <Menu.Item key={`23`} onClick={(e) => {

                                                                history.push({
                                                                    pathname: `/forms/${record.form}/records/${record.record_id}/change`,
                                                                    state: { mode: 'view' }
                                                                });

                                                            }}>View</Menu.Item>
                                                            <Menu.Item key={`3`} onClick={(e) => {

                                                                history.push({
                                                                    pathname: `/forms/${record.form}/records/${record.record_id}/change`,
                                                                    state: { mode: 'edit' }
                                                                });

                                                            }}>Edit</Menu.Item>
                                                            <Menu.Item key={`1`} onClick={(e) => {
                                                                exportAsPDF(e, record);
                                                            }} >Export To PDF</Menu.Item>

                                                            <Menu.Item key={`2`} onClick={(e) => {
                                                                deleteRecord(record, 1);
                                                            }}>Delete</Menu.Item>


                                                            <Menu.Item key={`4`} onClick={(e) => {
                                                                navigator.clipboard.writeText(`${window.location.origin}/forms/${record.form}/records/${record.record_id}/change`)
                                                            }}>Copy Link</Menu.Item>
                                                        </Menu>
                                                    }>

                                                    <MoreOutlined style={{ fontSize: 20, fontWeight: 'bold' }} />
                                                </Dropdown>


                                            </td>
                                        )
                                    }


                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={6} style={{ textAlign: 'right' }}>
                            <AntdButton type="primary" onClick={() => previous()}>Previous</AntdButton>
                            &nbsp;&nbsp;
                            <AntdButton type="primary" onClick={() => next()}>Next</AntdButton>
                        </td>
                    </tr>
                </tfoot>
            </Table >
        </div >
    }


    const previous = () => {
        getRecords(props?.records.previous, true);
    };

    const next = () => {
        getRecords(props?.records.next, true);
    };

    return (
        <div>
            <Modal
                width="100%"
                visible={showPDFModal}
                footer={null}
                onCancel={() => {
                    setShowPDFModal(false);
                }}
            >
                <div style={{ 'margin': '0px auto' }}>
                    {pdfPages}
                </div>
            </Modal>
            {
                loading ? (
                    <div className="blockUI">
                        <div className="loading-icon-container">
                            <LoadingOutlined />
                            <br />
                            <br />
                            <strong>Loading...</strong>
                        </div>
                    </div>
                ) : null
            }
            {
                props?.withCard ? generateTable() : generateCard(generateTable())
            }
        </div>

    );
}

function mapStateToProps(state) {
    const { records } = state.forms;
    const { user } = state.auth;
    return {
        records,
        user
    }
}
export default connect(mapStateToProps)(Records);